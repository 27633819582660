export const environment = {
  production: false,
  OAUTH_URL: 'https://oauth.api.dev.nexuscloud.com.br/api',
  API_URL: 'https://astutus.api.dev.nexuscloud.com.br/api',
  DNE_URL: 'https://dne.api.dev.nexuscloud.com.br/api',
  API_URL_CADASTRO: 'https://cadastro.api.dev.nexuscloud.com.br/api',
  API_URL_COMPRAS: 'https://compras.api.dev.nexuscloud.com.br/api',
  API_URL_DOMINIO: 'https://dominio.api.dev.nexuscloud.com.br/api',
  API_URL_ESTOQUE: 'https://estoque.api.dev.nexuscloud.com.br/api',
  API_URL_FINANCEIRO: 'https://financeiro.api.dev.nexuscloud.com.br/api',
  API_URL_VENDAS: 'https://vendas.api.dev.nexuscloud.com.br/api',
  API_URL_PRECOS: 'https://precos.api.dev.nexuscloud.com.br/api',
  API_URL_FISCAL: 'https://fiscal.api.dev.nexuscloud.com.br/api',
  API_URL_SYNC: 'https://sync.api.dev.nexuscloud.com.br/api',
  S3_API_URL: 'https://s3.api.dev.nexuscloud.com.br/api',
  API_URL_REPORT: 'https://report.api.dev.nexuscloud.com.br/api',
  API_URL_BACKUP: 'https://backup.api.dev.nexuscloud.com.br/api',
  API_URL_NOTIFICATION: 'https://notification.api.dev.nexuscloud.com.br/api',
};
